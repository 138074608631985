import { LogEvent } from '@analytics';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from 'tailwind-config';

import { Lottie } from '@components';

interface SliderContentProps {
  body: string;
  src: string;
  url: string;
  isBg?: boolean;
}

const CTAContents: SliderContentProps[] = [
  {
    body: '1종 보통 (수동)\n운전면허',
    src: '/home/one-normal-cta.svg',
    url: '/explore?code=ONE_MANUAL_NEW',
  },
  {
    body: '2종 보통 (자동)\n운전면허',
    src: '/home/two-auto-cta.svg',
    url: '/explore?code=TWO_AUTO_NEW',
  },
];

export const HomeCTA = () => {
  return (
    <div className="flex justify-between gap-10 p-16 pb-10">
      {CTAContents.map((content, index) => {
        const { body, src, url, isBg } = content;
        return (
          <motion.div
            whileTap={{ scale: 0.97 }}
            key={index}
            className={cn(
              'text-new-Section-Title flex-1 rounded-[16px] p-20 pb-16',
              isBg ? 'bg-[#FFFCA5]' : 'bg-new-white',
            )}
          >
            <Link
              key={index}
              onClick={() => {
                LogEvent.활성화.homeCTA('홈 CTA 버튼 : ' + url);
              }}
              href={url}
            >
              <div className="flex flex-col">
                <div className="flex items-start justify-between">
                  <div className="text-new-Section-Title break-keep">
                    {body.split('\n').map((text, index) => (
                      <span key={'span-' + index}>
                        {text}
                        <br />
                      </span>
                    ))}
                  </div>
                </div>
                <div className="self-end">
                  {src.includes('.json') ? (
                    <Lottie
                      path={src}
                      size={{
                        width: 64,
                        height: 64,
                      }}
                    />
                  ) : (
                    <Image
                      src={src}
                      alt={body}
                      width={150}
                      height={150}
                      className="h-[64px] w-[64px]"
                    />
                  )}
                </div>
              </div>
            </Link>
          </motion.div>
        );
      })}
    </div>
  );
};

import { useEffect, useRef, useState } from 'react';

import { LottiePlayer } from 'lottie-web';

interface LottieProps {
  path: string;
  size: {
    width: number;
    height: number;
  };
  style?: React.CSSProperties;
  loop?: boolean;
  autoplay?: boolean;
  play?: boolean;
}

export const Lottie = ({ path, size, style, loop = true, autoplay = true, play }: LottieProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (play && lottie) {
      lottie.play();
    }
  }, [play, lottie]);

  useEffect(() => {
    if (ref.current && lottie) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop,
        autoplay,
        path,
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return <div ref={ref} style={{ width: size.width, height: size.height, ...style }} />;
};
